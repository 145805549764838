import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { useActiveEmployees } from "../hooks/use-active-employees"
import { ActiveEmployee, ExEmployee } from "@igloonet-web/shared-ui"
import ProfileImage from "../components/profile-image"
import teamName from "../helpers/team-name"

export default function EmployeeDetail({ data }) {
  const employee = data.employeesYaml
  const employees = useActiveEmployees()
  let Employee = employee.ended ? ExEmployee : ActiveEmployee

  return (
    <Layout>
      <Employee
        employee={employee}
        employees={employees}
        teamName={teamName}
        profileImageTag={ProfileImage}
      />
    </Layout>
  )
}

export const EmployeeQuery = graphql`
  query EmployeeQuery($id: String!) {
    employeesYaml(id: { eq: $id }) {
      login
      nickname
      name
      surname
      sex
      teams
      telephone
      funny_position
      division
      name_with
      position
      profile_description
      social {
        facebook
        instagram
        twitter
        dribbble
        linkedin
        github
      }
      projects {
        name
        url
      }
      started(formatString: "D. M. YYYY")
      ended(formatString: "D. M. YYYY")
    }
  }
`
