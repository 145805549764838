// Vrátí jméno týmu tak, že vezme identifikátor a první písmeno dá na velké
// Abychom mohli podporovat diakritiku a speciality, tak lze název dát natvrdo přes konstantu teams

const teams = {
  vedeni: "Vedení",
  vyvoj: "Vývoj",
  ppc: "PPC",
}

export default function teamName(team) {
  return Object.prototype.hasOwnProperty.call(teams, team)
    ? teams[team]
    : team[0].toUpperCase() + team.slice(1)
}
